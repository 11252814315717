.PopOverActionsMenuCon1 {
  background: var(--app-white, #fff);
  z-index: 0;
  opacity: 0;
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  max-height: 50vh;
  padding: 0 12px 32px;
  transition: bottom .3s ease-in-out, opacity .3s ease-in-out;
  display: flex;
  position: absolute;
  bottom: -100%;
  overflow: hidden scroll;
  box-shadow: 0 -4px 4px #00000040;
}

.PopOverActionsMenuCon1Display {
  opacity: 1;
  bottom: 0;
}

.PopOverActionsMenuCon2 {
  border-bottom: .5px solid var(--app-charcoal-dark, #2b2b2b);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 12px;
  display: flex;
}

.PopOverActionsMenuText1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.PopOverActionsMenuCon3 {
  box-sizing: border-box;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 6px;
  display: flex;
}

.PopOverActionsMenuCon4 {
  background: #f3f3f3;
  border-bottom: .5px solid #f3f3f3;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px;
  display: flex;
}

.PopOverActionsMenuCon5 {
  border-bottom: .5px solid #f3f3f3;
  border-bottom: .5px solid var(--money-out-background-color, #ffebeb);
  background: #f3f3f3;
  background: var(--money-out-background-color, #ffebeb);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px;
  display: flex;
}

.PopOverActionsMenuText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.PopOverActionsMenuText3 {
  color: var(--Danger-color-red, red);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/*# sourceMappingURL=index.c8b64ce3.css.map */

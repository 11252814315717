.PopOverActionsMenuCon1 {
  position: absolute;
  display: flex;
  padding: 0px 12px 32px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  max-height: 50vh;

  border-radius: 12px 12px 0px 0px;
  background: var(--app-white, #fff);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  overflow-y: scroll;
  bottom: -100%;
  z-index: 0;

  /* 🔥 Add smooth transition */
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0; /* Initially hidden */
}

/* When the menu is displayed */
.PopOverActionsMenuCon1Display {
  bottom: 0;
  opacity: 1; /* Fully visible */
}

.PopOverActionsMenuCon2 {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
}

.PopOverActionsMenuText1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PopOverActionsMenuCon3 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
}
.PopOverActionsMenuCon4 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 0.5px solid #f3f3f3;
  background: #f3f3f3;
  border-bottom: 0.5px solid #f3f3f3;
  background: #f3f3f3;
}
.PopOverActionsMenuCon5 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 0.5px solid #f3f3f3;
  background: #f3f3f3;

  border-bottom: 0.5px solid var(--money-out-background-color, #ffebeb);
  background: var(--money-out-background-color, #ffebeb);
}
.PopOverActionsMenuText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PopOverActionsMenuText3 {
  color: var(--Danger-color-red, #f00);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
